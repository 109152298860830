<template>
  <div class="adduser-list-page">
    <PocCardTable class="irisePocCardTable">
      <template v-slot:headerTitle>
        <span
          v-if="jumpFlag == 'add' || jumpFlag == 'saveAssign'"
          style="font-weight: 400"
          ><span class="userMan">用户管理</span> / 新增用户</span
        >
        <span v-else-if="jumpFlag == 'edit'" style="font-weight: 400"
          ><span class="userMan">用户管理</span> / 编辑用户</span
        >
        <span v-else-if="jumpFlag == 'copy'" style="font-weight: 400"
          ><span class="userMan">用户管理</span> / 复制用户</span
        >
        <span v-else-if="jumpFlag == 'goDetail'" style="font-weight: 400"
          ><span class="userMan">用户管理</span> / 用户详情</span
        >
      </template>
      <template v-slot:headerGroup>
        <el-button @click="cancle">返回</el-button>
        <el-button
          type="primary"
          role="muldelete"
          v-permission
          @click="goEdit"
          v-if="jumpFlag == 'goDetail'"
        >
          编辑
        </el-button>
        <el-button
          type="primary"
          role="save"
          v-permission
          @click="submit"
          v-else
        >
          保存
        </el-button>
      </template>
      <template>
        <!-- 详情的区域 -->
        <div v-if="jumpFlag == 'goDetail'">
          <div class="flex-1 flex AlCenter">
            <div class="flex-1 flexBetw">
              <div class="flex-1">
                <span class="lableDetail">用户编码</span>
                <span class="lableContent">{{ selectEditMsg.userCode }}</span>
              </div>
              <div class="flex-1">
                <span class="lableDetail">用户名</span>
                <span class="lableContent">{{ selectEditMsg.userName }}</span>
              </div>
              <div class="flex-1">
                <span class="lableDetail">手机号</span>
                <span class="lableContent">{{ selectEditMsg.mobile }}</span>
              </div>
              <div class="flex-1">
                <span class="lableDetail">电子邮件</span>
                <span class="lableContent">{{ selectEditMsg.email }}</span>
              </div>
            </div>
            <div class="upWord" style="width: 82px"></div>
          </div>
          <div
            class="flex-1 flex AlCenter"
            style="margin-top: 12px; margin-bottom: 12px"
          >
            <div class="flex-1 flexBetw">
              <div class="flex-1">
                <span class="lableDetail">有效期限</span>
                <span class="lableContent"
                  >{{ selectEditMsg.beginTime
                  }}<span style="margin-left: 10px; margin-right: 10px"
                    >至</span
                  >
                  <span>{{
                    selectEditMsg.endTime ? selectEditMsg.endTime : '永久'
                  }}</span>
                </span>
              </div>
              <div class="flex-1">
                <span class="lableDetail">启用状态</span>
                <span class="lableContent" v-if="selectEditMsg.status == 0"
                  >启用</span
                >
                <span class="lableContent" v-else>停用</span>
              </div>
              <div class="flex-1">
                                <span class="lableDetail">用户类型</span>      
                         
                <span class="lableContent">{{
                  selectEditMsg.userTypeName
                }}</span>
                             
              </div>
                           
              <div class="flex-1">
                                <span class="lableDetail">备注</span>          
                     
                <span class="lableContent">{{ selectEditMsg.remark }}</span>    
                         
              </div>
            </div>
            <div class="upWord" style="width: 82px"></div>
          </div>
        </div>
        <!-- 新增，编辑，复制等区域 -->
        <PocSlider minheight="70px" maxHeight="300px" btntype="primary" v-else>
          <el-form :inline="true" label-position="top">
            <el-form-item label="用户编码">
              <el-input
                placeholder="请输入用户编码"
                v-model="selectEditMsg.userCode"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="用户名">
              <el-input
                placeholder="请输入用户名"
                v-model="selectEditMsg.userName"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                placeholder="请输入手机号"
                v-model="selectEditMsg.mobile"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="电子邮件">
              <el-input
                placeholder="请输入电子邮件"
                v-model="selectEditMsg.email"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="启用状态">
              <el-select
                clearable
                v-model="selectEditMsg.status"
                filterable
                placeholder="请选择类型"
                class="industry-selector"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生效日期">
              <el-date-picker
                v-model="selectEditMsg.beginTime"
                type="date"
                align="right"
                unlink-panels
                :clearable="true"
                range-separator="-"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="yyyy-MM-dd"
                placeholder="请选择生效日期"
                :picker-options="pickerOptions1"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="失效日期">
              <el-date-picker
                v-model="selectEditMsg.endTime"
                type="date"
                align="right"
                unlink-panels
                :clearable="true"
                value-format="yyyy-MM-dd"
                placeholder="请选择失效日期"
                :picker-options="pickerOptions2"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="用户类型">
              <el-cascader
                ref="parentref"
                clearable
                v-model="cascaderMod"
                :props="{ label: 'name', value: 'id', multiple: true }"
                :options="userTypeDataList"
                collapse-tags
                @change="handleChange"
                placeholder="请输入所属组织"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                placeholder="请输入备注"
                v-model="selectEditMsg.remark"
                maxlength="50"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-form>
        </PocSlider>
      </template>
      <template>
        <div class="tabsDis">
          <el-tabs v-model="chooseTab" @tab-click="changeTab">
            <el-tab-pane label="已分配组织" name="1"></el-tab-pane>
            <el-tab-pane label="已分配角色" name="2"></el-tab-pane>
            <el-tab-pane label="已分配权限" name="3"></el-tab-pane>
          </el-tabs>
          <el-button
            type="primary"
            class="buttonActiveBig"
            :class="orgFlag ? 'greyButton' : ''"
            :disabled="orgFlag"
            role="assignorgs"
            v-permission
            @click="distributionRole"
            >分配角色</el-button
          >
        </div>
      </template>

      <PocTable
        v-loading="queryLoading"
        :data="tableData"
        stripe
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        :max-height="100000000000"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          show-overflow-tooltip
        >
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <scopeTransfer
      ref="scopeTransferRef"
      @onConfirm="onConfirm"
      dialog-title="分配角色"
    />
  </div>
</template>

<script>
import { getTheyearOptions, stampToTime } from '@/utils/util'
const { Vuex } = $PCommon
const { mapGetters } = Vuex
import scopeTransfer from '@/components/ScopeTransfer/ScopeTransfer'
import pocSlider from '@/components/poc-slider/poc-slider'
const { PocResetMessage, $sessionStorage } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    scopeTransfer,
    pocSlider
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
    const cdate = this.$moment().format('YYYY-MM-DD')
    let disabledDate1 = (time) => {
      console.log(this)
      return time.getTime() > this.$moment(this.selectEditMsg.endTime).valueOf()
      // 设置选择今天以及今天之后的日
    }
    let disabledDate2 = (time) => {
      console.log(this)
      return (
        time.getTime() < this.$moment(this.selectEditMsg.beginTime).valueOf()
      )
      // 设置选择今天以及今天之后的日
    }
    return {
      maxHeight: h,
      queryLoading: false,
      downloadLoading: false,
      deleteLoading: false,
      tableData: [],
      chooseFlag: false,
      scopeData: [],
      ifLeaderFlag: false,
      upFlag: true,
      orgFlag: false, // 分配按钮默认为可以点击
      columnConfig: [
        {
          key: 'orgCode',
          name: '组织编码',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'orgName',
          name: '组织名称 ',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createUser',
          name: '授权人',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createTime',
          name: '授权时间',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        }
      ],
      columnConfig2: [
        {
          key: 'roleCode',
          name: '角色编码',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'roleName',
          name: '角色名称 ',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createUser',
          name: '授权人',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createTime',
          name: '授权时间',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        }
      ],
      columnConfig3: [
        {
          key: 'permissionCode',
          name: '权限编码',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'permissionName',
          name: '权限名称 ',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createUser',
          name: '授权人',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createTime',
          name: '授权时间',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        }
      ],
      selectEditMsg: {
        assignedGroupList: '',
        assignedRoleList: '',
        beginTime: '',
        email: '',
        endTime: '',
        mobile: '',
        remark: '',
        status: '',
        userCode: '',
        userName: '',
        userTypeList: []
      }, // 选中修改的一系列用户信息
      selectIdArr: [], // 选中数组id集合
      chooseTab: '1', // 默认选中已分配机构
      statusList: [
        { id: 0, name: '启用' },
        { id: 1, name: '停用' }
      ],
      failturesList: [
        { id: 0, name: '未锁定' },
        { id: 1, name: '已锁定' }
      ],
      tabLabel: {
        1: '已分配机构',
        2: '已分配角色',
        3: '已分配权限'
      },
      userTypeDataList: [],
      cascaderMod: '',
      endTimeRemind: '',
      jumpFlag: '',
      singleId: '',
      appName: '',
      addFlag: true,
      valData: '',
      value: [],
      editApplyId: '', // 编辑选中的楼层ID
      buttonDisable: false, // 按钮默认可点
      selectFlag: false, // 编辑的时候是否选择多选框
      props: { multiple: true },
      pickerOptions1: {
        disabledDate: disabledDate1
      },
      pickerOptions2: {
        disabledDate: disabledDate2
      },
      target: ''
    }
  },
  props: ['jumpParams'],
  computed: {
    ...mapGetters(['userInfo']),
    theyearOptions() {
      // 获取年份列表
      return getTheyearOptions()
    },
    columnOptions() {
      if (this.chooseTab == 1) {
        return this.columnConfig.filter((item) => item.visible)
      } else if (this.chooseTab == 2) {
        return this.columnConfig2.filter((item) => item.visible)
      } else {
        return this.columnConfig3.filter((item) => item.visible)
      }
    },
    ...mapGetters(['userInfo'])
  },

  created() {
    this.init()
  },
  methods: {
    init() {
      this.selectEditMsg.beginTime = this.getCurrentYearMonthFirst()
      this.jumpFlag = this.jumpParams.jumpFlag
      this.singleId = this.jumpParams.singleId
      this.target = this.jumpParams.target
      if (
        this.jumpFlag == 'edit' ||
        this.jumpFlag == 'copy' ||
        this.jumpFlag == 'goDetail'
      ) {
        // 请求单个用户的信息
        this.getsingleMsg(this.singleId)
        this.filterQuery() // 新增时候不查这个接口
      }
      if (!this.singleId) {
        // 新增进来没有id,分配组织机构按钮不可点
        this.orgFlag = true
      }
      this.getuserTypeList()
    },
    filterQuery() {
      this.pageConfig.pageNum = 1

      this.queryApi()
      // this.queryLoading = true;
    },
    async getuserTypeList() {
      const { code, data } = await this.$Api.dataMain.getuserTypeList({})
      if (code === 200) {
        this.userTypeDataList = data
      }
    },
    // changeLeader(){
    //   this.ifLeaderFlag=!this.ifLeaderFlag;
    //   console.log('isLeader',this.ifLeaderFlag)
    //   this.selectEditMsg.isLeader=this.ifLeaderFlag
    // },
    getCurrentYearMonthFirst() {
      console.log('getCurrentYearMonthFirst')
      var date = new Date()
      // date.setDate(1);
      var month = parseInt(date.getMonth())
      month = month + 1
      var day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        console.log('===', day)
        day = '0' + day
      }
      return date.getFullYear() + '-' + month + '-' + day
    },

    onFilter(type, value) {
      console.log('99999999', value)
      this.$forceUpdate()
      this.selectEditMsg.beginTime = value[0]
      this.selectEditMsg.endTime = value[1]
    },
    dealStatus(status) {
      switch (status) {
        case '0': {
          return '有效'
        }
        case '1': {
          return '失效'
        }
        default: {
          return '-'
        }
      }
    },
    // 获取已分配机构
    async queryApi() {
      this.queryLoading = true
      const tab = {
        1: 'getOrgList',
        2: 'getRoleList',
        3: 'getPermissionList'
      }
      console.log(',,,,,,,,参数3', this.pageConfig)
      const { data, code, msg } = await this.$Api.dataMain[tab[this.chooseTab]](
        {
          userId: this.singleId,
          ...this.pageConfig
        }
      )
      this.queryLoading = false
      if (code === 200) {
        this.tableData = data.list
        if (this.chooseTab == 2) {
          this.tableData
            ? this.tableData
            : [].map((v) => {
                v.orgName = v.roleName
                v.orgCode = v.roleCode
              })
          this.pageConfig.total = data.total
        } else if (this.chooseTab == 3) {
          this.tableData
            ? this.tableData
            : [].map((v) => {
                v.orgName = v.permissionName
                v.orgCode = v.permissionCode
              })
          this.pageConfig.total = data.total
        }
        this.pageConfig.total = data.total
      }
    },
    upRow() {
      this.upFlag = !this.upFlag
    },
    changeTab(activeTab) {
      // this.chooseTab = activeTab
      this.queryApi()
    },
    async onConfirm(val) {
      // if (val) {
      this.valData = val
      if (this.chooseTab == 1) {
        console.log(val)
        this.selectEditMsg.assignedRoleList = this.valData
      } else if (this.chooseTab == 2) {
        this.selectEditMsg.assignedRoleList = this.valData
      }
      await this.submit()
      await this.$emit('goback')
      // }
    },
    goEdit(row) {
      this.jumpFlag = 'edit'
    },

    // 调出穿梭框
    distributionOrganization() {
      this.$refs.scopeTransferRef.show({
        id: this.singleId || '',
        positions: 'adduser'
      })
    },
    // 分配角色==调出穿梭框
    distributionRole() {
      this.$refs.scopeTransferRef.show({
        id: this.singleId || '',
        positions: 'disRole',
        treeTitle: ['待分配角色', '已分配角色']
      })
    },
    handleChange(value) {
      this.selectEditMsg.userTypeList = value
      console.log(
        '9999999999999999999999提交接口',
        this.selectEditMsg.userTypeList
      )
    },
    async submit() {
      this.buttonDisable = true
      const phoneReg = RegExp(/^0?1[3|4|5|7|8|9][0-9]\d{8}$/) // 只能是中文
      const passwordStrongCheck =
        /(?=.*[A-Za-z0-9])(?=.*[A-za-z])(?=.*[0-9])(?=.*[~!@#$%^&*_.]).{8,}/
      const regEmail =
        /^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/

      if (!this.selectEditMsg.userName) {
        this.$message({
          type: 'error',
          message: '用户名为空!'
        })
        this.buttonDisable = false
        return
      }
      if (!this.selectEditMsg.userCode) {
        this.$message({
          type: 'error',
          message: '用户编码为空!'
        })
        this.buttonDisable = false
        return
      }
      const chineseReg = RegExp(/[\u4E00-\u9FA5]/g)
      if (chineseReg.test(this.selectEditMsg.userCode)) {
        this.$message({
          type: 'error',
          message: '用户编码不可为中文!'
        })
        this.buttonDisable = false
        return
      }

      if (!this.selectEditMsg.beginTime) {
        this.$message({
          type: 'error',
          message: '生效日期为空!'
        })
        this.buttonDisable = false
        return
      }
      if (this.selectEditMsg.mobile) {
        if (!phoneReg.test(this.selectEditMsg.mobile)) {
          this.$message({
            type: 'error',
            message: '电话格式不规范!'
          })
          this.buttonDisable = false
          return
        }
      }
      if (this.selectEditMsg.email) {
        if (!regEmail.test(this.selectEditMsg.email)) {
          this.$message({
            type: 'error',
            message: '邮箱不规范!'
          })
          this.buttonDisable = false
          return
        }
      }
      if (this.selectEditMsg.status === '') {
        this.$message({
          type: 'error',
          message: '状态为空!'
        })
        this.buttonDisable = false
        return
      }
      if (this.jumpFlag == 'add' || this.jumpFlag == 'copy') {
        // 新增函数
        //复制的时候未选择分配角色时用带过来的数据
        this.selectEditMsg.assignedRoleList = this.valData
          ? this.valData
          : this.selectEditMsg.roleIds
          ? this.selectEditMsg.roleIds
          : ''

        if (this.jumpFlag == 'copy') {
          //复制的时候加个id
          this.selectEditMsg.oldUserId = this.jumpParams.singleId
        }
        console.log('填写应用的信息', this.selectEditMsg)

        const { code, data, msg } = await this.$Api.dataMain.addUser({
          ...this.selectEditMsg,
          id: ''
        })
        this.buttonDisable = false
        this.queryLoading = false
        if (code == 200) {
          if (data) {
            console.log('copy新增接口============', data, this.singleId)
            // data为返回的新增userId的值
            this.orgFlag = false // 此时分配组织按钮可点
            this.jumpFlag = 'saveAssign' // 新增完如点组织按钮 可点.需走edit接口（PS：一样数据不能用户名一样走2次接口）
            this.singleId = data // 赋予编辑id的值
          }
          this.$message({
            type: 'success',
            message: '新增成功!'
          })
          // this.$emit('goback')
        } else {
          this.$message({
            type: 'info',
            message: msg
          })
        }
      } else {
        console.log('是否重新选择', this.selectFlag)
        const { data, code, msg } = await this.$Api.dataMain.addUser({
          ...this.selectEditMsg,
          id: this.singleId
        })
        this.buttonDisable = false
        if (code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.$emit('goback')
        } else {
          this.$message({
            type: 'info',
            message: msg
          })
        }
      }
    },
    async getsingleMsg(singleId) {
      const { data, code, msg } = await this.$Api.dataMain.getSingleMsg({
        id: singleId
      })
      if (code === 200) {
        this.selectEditMsg = data
        this.selectEditMsg.status = Number(data.status)
        if (!this.selectEditMsg.endTime) {
          this.endTimeRemind = '永久'
        }
        this.cascaderMod = this.selectEditMsg.userTypeList
        console.log('单个数据', this.selectEditMsg)
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    // 编辑信息
    async editdRow(row) {
      console.log('选中修改信息-----', row)
      // await this.getscopeList()

      this.addFlag = false
      this.selectEditMsg = JSON.parse(JSON.stringify(row))
      this.editApplyId = row.id
    },
    // 关闭侧边栏
    cancle() {
      if (this.addFlag) {
        if (
          this.target &&
          this.target === 'goDetail' &&
          this.jumpFlag !== 'goDetail'
        ) {
          this.selectEditMsg = {
            beginTime: '',
            email: '',
            endTime: '',
            mobile: '',
            remark: '',
            status: '',
            userCode: '',
            userName: ''
          }
          this.jumpFlag = 'goDetail'
          this.init()
          this.target = ''
        } else {
          this.selectEditMsg = {
            beginTime: '',
            email: '',
            endTime: '',
            mobile: '',
            remark: '',
            status: '',
            userCode: '',
            userName: ''
          }
          this.$emit('goback')
        }
      }

      this.selectFlag = false // 重新选择置为false
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.upWord {
  color: #ba9765;
  font-size: 14px;
  margin-left: 1px;
  cursor: pointer;
  margin-left: 8px;
}
.scopeItem {
  margin-right: 72px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
}
.active {
  color: #303133;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 14px;
  width: 125px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  padding: 0px 0 14px 0;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
  }
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.up {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: rgba(186, 151, 101, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .strangle {
    width: 0;
    height: 0;
    border: 6px solid;
    border-color: transparent transparent #ba9765 transparent;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -3px;
    &.down {
      border-color: #ba9765 transparent transparent transparent;
      top: 3px;
    }
  }
}
.drawerPadding {
  height: 83vh;
  padding: 0px 64px 20px 74px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}
.drawerTitle,
.drawerTitle span {
  color: #303133;
  font-size: 16px;
  font-weight: bold !important;
}
.iconImg {
  width: 16px;
  height: 16px;
}
.drawerContainer {
  padding: 30px 20px 20px 20px;
  box-sizing: border-box;
}
.drawerPadding {
  padding: 0px 64px 20px 74px;
  box-sizing: border-box;
}
.bottomButton {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
}
.bottomBox {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 20px;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.buttonActive {
  width: 76px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}

.buttonUnactive {
  width: 76px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.lableDetail {
  font-size: 14px;
  font-family: Roboto;
  color: #4e4e50;
  margin-right: 15px;
}
.lableContent {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  color: #545454;
}
.lableContent span {
  //color: #545454;
  //font-size: 14px;
  //font-family: PingFangSC-Regular, PingFang SC;
}
.SelectedCheckImg {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
.ifLeader {
  font-size: 14px;
  color: #868686;
}
</style>
<style lang="scss">
.adduser-list-page {
  .el-card {
    .el-card__header {
      border-bottom: none !important;
    }
  }
  .el-button + .el-button {
    margin-left: 16px;
  }
  .el-table__header-wrapper .el-checkbox {
    display: none;
  }

  .irisePocCardTable {
    //height: 100%;
    .el-card__header {
      height: 65px;
    }
  }
  .el-cascader input {
    height: 28px !important;
  }
  .el-cascader__tags .el-tag {
    max-width: 70%;
  }
}
.tabsDis {
  display: flex;
  position: relative;
  margin-top: 30px;
  .el-tabs {
    width: 100%;
  }
  .buttonActiveBig {
    width: 104px;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 15px;
    position: absolute;
    right: 0;
    bottom: 23px;
  }
}
.userMan {
  font-size: 21px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #868686;
}
</style>
