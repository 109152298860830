<template>
  <div>
    <div v-show="!manageShow" class="user-list-page">
      <PocCardTable class="irisePocCardTable topPocCardTable">
        <template v-slot:headerTitle>用户列表</template>
        <template v-slot:headerGroup>
          <el-button
            v-if="selectIds.length"
            @click="lockRow(selectIds, '1', '停用')"
            role="disableStatus"
            v-permission
            >停用</el-button
          >
          <el-button
            v-if="selectIds.length"
            @click="lockRow(selectIds, '1', '启用')"
            role="disableStatus"
            v-permission
            >启用</el-button
          >
          <el-button
            v-if="selectIds.length"
            @click="lockUser(selectIds, '1')"
            role="disableStatus"
            v-permission
            >解锁</el-button
          >
          <el-button
            :loading="exportLoading"
            role="export"
            v-permission
            @click="exportData"
            >导出</el-button
          >
          <el-button :loading="queryLoading" @click="filterQuery"
            >查询</el-button
          >
          <el-button type="primary" @click="addUser" role="save" v-permission
            >新增用户</el-button
          >
          <el-button
            type="primary"
            @click="resetPass"
            role="resetPassword"
            v-permission
            >重置密码</el-button
          >
        </template>
        <template>
          <el-form
            :inline="true"
            label-position="top"
            class="minWidthInput minwidthBottom"
          >
            <el-form-item label="用户名">
              <el-input
                placeholder="请输入用户名"
                v-model="filterConfig.userName"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="角色名称">
              <el-input
                placeholder="请输入角色名称"
                v-model="filterConfig.roleName"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="用户类型">
              <el-cascader
                ref="parentref"
                clearable
                v-model="cascaderMod"
                :props="{ label: 'name', value: 'id', multiple: true }"
                :options="userTypeDataList"
                collapse-tags
                @change="handleChange"
                placeholder="请输入用户类型"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                placeholder="请输入手机号"
                v-model="filterConfig.mobile"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="启用状态">
              <el-select
                clearable
                v-model="filterConfig.status"
                filterable
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="锁定状态">
              <el-select
                clearable
                v-model="filterConfig.failtures"
                filterable
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in failturesList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
      </PocCardTable>
      <PocCardTable class="irisePocCardTable">
        <PocTable
          v-loading="queryLoading"
          :data="tableData"
          stripe
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
          @selection-change="handleSelectionChange"
          @row-click="goDetail"
          max-height="10000000"
          ref="multipleTable"
          row-key="id"
        >
          <el-table-column
            type="selection"
            width="45"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <svg-icon
                icon="tableEdit"
                role="edit"
                class="tablePointSvgClass"
                v-permission
                title="编辑"
                @click.native.stop="editdRow(scope.row)"
              ></svg-icon>

              <svg-icon
                v-if="scope.row.lockStatus == '未锁定'"
                icon="tableLock"
                role="unlock"
                class="tablePointSvgClass"
                v-permission
                @click.native.stop=""
                title="不可锁定，无权限"
              ></svg-icon>
              <svg-icon
                v-else
                icon="tableUnlock"
                role="unlock"
                class="tablePointSvgClass"
                v-permission
                title="解锁"
                @click.native.stop="lockUser(scope.row)"
              ></svg-icon>

              <svg-icon
                v-if="scope.row.status == '启用'"
                icon="tableLockRow"
                role="enableStatus"
                class="tablePointSvgClass"
                v-permission
                title="停用"
                @click.native.stop="lockRow(scope.row)"
              ></svg-icon>
              <svg-icon
                v-if="scope.row.status == '停用'"
                icon="tableOpen"
                role="disableStatus"
                class="tablePointSvgClass"
                v-permission
                title="启用"
                @click.native.stop="lockRow(scope.row)"
              ></svg-icon>

              <svg-icon
                icon="tableDelete"
                role="delete"
                class="tablePointSvgClass"
                v-permission
                title="删除"
                @click.native.stop="deleteRow(scope.row)"
              ></svg-icon>
              <svg-icon
                icon="tableCopy"
                role="copy"
                class="tablePointSvgClass"
                v-permission
                title="复制"
                @click.native.stop="copy(scope.row)"
              ></svg-icon>

              <!--              <el-row :gutter="24">-->
              <!--                <el-col :span="4">-->
              <!--                  <el-tooltip content="编辑" placement="top">-->
              <!--                    <img-->
              <!--                      class="iconImg pointer"-->
              <!--                      role="edit"-->
              <!--                      v-permission-->
              <!--                      src="@/assets/img/edit.png"-->
              <!--                      @click="editdRow(scope.row)"-->
              <!--                      alt=""-->
              <!--                    />-->
              <!--                  </el-tooltip>-->
              <!--                </el-col>-->
              <!--                <el-col :span="4">-->
              <!--                  <el-tooltip-->
              <!--                    v-if="scope.row.lockStatus == '未锁定'"-->
              <!--                    content="不可锁定，无权限"-->
              <!--                    placement="top"-->
              <!--                  >-->
              <!--                    <img-->
              <!--                      class="iconImg"-->
              <!--                      role="unlock"-->
              <!--                      v-permission-->
              <!--                      src="@/assets/img/locked.png"-->
              <!--                      alt=""-->
              <!--                    />-->
              <!--                  </el-tooltip>-->
              <!--                  <el-tooltip content="解锁" v-else placement="top">-->
              <!--                    <img-->
              <!--                      class="iconImg pointer"-->
              <!--                      role="unlock"-->
              <!--                      v-permission-->
              <!--                      src="@/assets/img/lock.png"-->
              <!--                      @click.stop="lockUser(scope.row)"-->
              <!--                      alt=""-->
              <!--                    />-->
              <!--                  </el-tooltip>-->
              <!--                </el-col>-->
              <!--                <el-col :span="4">-->
              <!--                  <el-tooltip-->
              <!--                    v-if="scope.row.status == '停用'"-->
              <!--                    content="启用"-->
              <!--                    placement="top"-->
              <!--                  >-->
              <!--                    <img-->
              <!--                      class="iconImg pointer"-->
              <!--                      role="enableStatus"-->
              <!--                      v-permission-->
              <!--                      src="@/assets/img/start.png"-->
              <!--                      @click.stop="lockRow(scope.row)"-->
              <!--                      alt=""-->
              <!--                    />-->
              <!--                  </el-tooltip>-->
              <!--                  <el-tooltip-->
              <!--                    v-if="scope.row.status == '启用'"-->
              <!--                    content="停用"-->
              <!--                    placement="top"-->
              <!--                  >-->
              <!--                    <img-->
              <!--                      class="iconImg pointer"-->
              <!--                      role="disableStatus"-->
              <!--                      v-permission-->
              <!--                      src="@/assets/img/prohibit.png"-->
              <!--                      @click.stop="lockRow(scope.row)"-->
              <!--                      alt=""-->
              <!--                    />-->
              <!--                  </el-tooltip>-->
              <!--                </el-col>-->
              <!--                <el-col :span="4">-->
              <!--                  <el-tooltip content="删除" placement="top">-->
              <!--                    <img-->
              <!--                      class="iconImg"-->
              <!--                      role="delete"-->
              <!--                      v-permission-->
              <!--                      src="@/assets/img/delete.png"-->
              <!--                      @click.stop="deleteRow(scope.row)"-->
              <!--                      alt=""-->
              <!--                    />-->
              <!--                  </el-tooltip>-->
              <!--                </el-col>-->
              <!--                <el-col :span="4">-->
              <!--                  <el-tooltip content="复制" placement="top">-->
              <!--                    <img-->
              <!--                      class="iconImg pointer"-->
              <!--                      role="copy"-->
              <!--                      v-permission-->
              <!--                      src="@/assets/img/copy.png"-->
              <!--                      @click="copy(scope.row)"-->
              <!--                      alt=""-->
              <!--                    />-->
              <!--                  </el-tooltip>-->
              <!--                </el-col>-->
              <!--              </el-row>-->
            </template>
          </el-table-column>
        </PocTable>
      </PocCardTable>
    </div>
    <add-user-manage
      v-if="manageShow"
      :jumpParams="jumpParams"
      @goback="goback"
    />
    <el-dialog
      :visible.sync="ModalFlagSuccess"
      width="354px"
      top="230px"
      title="导出提示"
    >
      <div style="text-align: center; font-size: 20px">
        {{ exportMsg }}已成功导出！
      </div>
      <div style="text-align: right; margin-top: 38px">
        <el-button type="primary" @click="close" style="margin-bottom: 20px"
          >知道了（{{ exportLeftSecond }}S）</el-button
        >
        <div class="clear"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTheyearOptions, stampToTime } from '@/utils/util'
const { Vuex } = $PCommon
const { mapGetters } = Vuex

import AddUserManage from './addUserManage.vue'
const { PocResetMessage, $sessionStorage } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    AddUserManage
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
    return {
      maxHeight: h,
      queryLoading: false,
      exportLoading: false,
      downloadLoading: false,
      deleteLoading: false,
      ModalFlagSuccess: false,
      exportLeftSecond: 3,
      exportMsg: '',
      tableData: [],
      chooseFlag: false,
      seletAllFlag: false,
      checked: false,
      ifSureFlag: true,
      scopeData: [],
      cascaderMod: '',
      userTypeDataList: [],
      columnConfig: [
        {
          key: 'userCode',
          name: '用户名编码',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 130 // 默认宽度，像素
        },
        {
          key: 'userName',
          name: '用户名',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'userType',
          name: '用户类型',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'roleName',
          name: '角色名称',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'mobile',
          name: '手机号码',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'email',
          name: '电子邮件',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 240 // 默认宽度，像素
        },
        {
          key: 'createInfo',
          name: '创建信息',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'status',
          name: '启用信息',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },
        {
          key: 'lockStatus',
          name: '是否锁定',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        }
      ],
      filterConfig: {
        failtures: '',
        status: '',
        roleName: '',
        mobile: '',
        userName: '',
        userType: ''
      },
      selectIds: [], // 选中数组id集合字符串
      statusList: [
        { id: '0', name: '启用' },
        { id: '1', name: '停用' }
      ],
      failturesList: [
        { id: '0', name: '未锁定' },
        { id: '1', name: '已锁定' }
      ],
      props: { multiple: true },
      manageShow: false,
      jumpParams: {},
      firstSelectItem: {},
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    theyearOptions() {
      // 获取年份列表
      return getTheyearOptions()
    },
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    },
    ...mapGetters(['userInfo'])
  },

  async created() {
    await this.filterQuery()
    await this.getuserTypeList()
  },
  methods: {
    goback() {
      console.log('============')
      this.manageShow = false
      this.filterQuery()
    },
    close() {
      this.ModalFlagSuccess = false
      this.exportLeftSecond = 3
    },
    filterQuery() {
      this.pageConfig.pageNum = 1
      this.queryApi()
      // this.queryLoading = true;
    },
    async getuserTypeList() {
      const { code, data } = await this.$Api.dataMain.getuserTypeList({})
      if (code === 200) {
        this.userTypeDataList = data
      }
    },
    dealStatus(status) {
      switch (status) {
        case '0': {
          return '有效'
        }
        case '1': {
          return '失效'
        }
        default: {
          return '-'
        }
      }
    },
    // 获取基础数据维护的列表
    async queryApi() {
      this.queryLoading = true
      console.log(',,,,,,,,参数', this.filterConfig)
      const { data, code } = await this.$Api.dataMain.getDataList({
        ...this.filterConfig,
        ...this.pageConfig
      })
      this.queryLoading = false
      if (code === 200) {
        this.tableData = data.list
        this.pageConfig.total = data.total
        this.$forceUpdate()
      }
    },
    handleChange(value) {
      this.filterConfig.userType = value.toString()
    },
    handleSelectionChange(val) {
      // 选的第一个
      if (val.length) {
        this.ifSureFlag = false
        this.selectedItems = val
        this.selectIds = this.selectedItems.map((v) => v.id).join()
      } else {
        this.ifSureFlag = true
      }
    },
    selectAll(e) {
      if (e == true) {
        //全部选中
        this.seletAllFlag = true
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.seletAllFlag = false
        this.$refs.multipleTable.clearSelection()
      }
    },
    goDetail(row) {
      this.manageShow = true
      this.jumpParams = {
        jumpFlag: 'goDetail',
        target: 'goDetail',
        singleId: row.id
      }
    },
    async exportData() {
      console.log('导出------')
      this.pageConfig.total = 0
      this.exportLoading = true
      await this.queryApi()
      console.log('8888888', this.pageConfig.total, this.pageConfig.total == 0)
      if (this.pageConfig.total == 0) {
        this.$message({
          type: 'error',
          message: '用户数据为空，无法导出'
        })
        this.queryLoading = false
        return
      }
      console.log(
        'this.$Api.dataMain.exportDataList2',
        this.$Api.dataMain.exportDataList2
      )
      await this.$Api.dataMain.exportDataList2({
        ...this.filterConfig,
        ...this.pageConfig
      })
      this.exportLoading = false
      this.exportShowTip()
    },
    resetPass() {
      let obj = {
        ids: this.selectedItems.map((item) => item.id).toString(',')
      }
      this.$Api.dataMain.resetPasswod(obj).then((res) => {
        if (res && res.code === 200) {
          this.$message({
            type: 'success',
            message: '重置成功'
          })
        }
        this.selectIds = ''
        this.$refs.multipleTable.clearSelection()
      })
    },
    exportShowTip() {
      this.exportMsg = '用户数据'
      this.ModalFlagSuccess = true
      this.exportLeftSecond = 3
      const timer = setInterval(() => {
        if (this.exportLeftSecond > 0) {
          this.exportLeftSecond--
        } else {
          clearInterval(timer)
          this.ModalFlagSuccess = false
        }
      }, 1000)
    },
    deleteRow(index, chooseMore) {
      let rowId = ''
      if (chooseMore == 1) {
        //多选
        rowId = this.selectIds
      } else {
        rowId = index.id
      }
      this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getDeleteReason(rowId)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 删除原因，子组件回调
    async getDeleteReason(ids) {
      const { code } = await this.$Api.dataMain.deleteRow({
        ids
      })
      if (code == 200) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.queryApi()
        // 成功之后全部取消选中状态
        this.selectIds = ''
        this.$refs.multipleTable.clearSelection()
        this.ifSureFlag = true
      }
    },
    // 取消停用
    async lockRow(row, chooseMore, chooseMorestatus) {
      let id = ''
      let rowStatus = ''
      if (chooseMore == 1) {
        console.log('多选功能', chooseMorestatus)
        // 多选功能
        // chooseMorestatus传过来的操作动作，不是列表状态与row.status相反
        id = this.selectIds
        rowStatus = chooseMorestatus
        let status = ''
        let msg = ''
        if (rowStatus == '启用') {
          status = 0
          msg = '取消停用成功!'
        } else {
          status = 1
          msg = '停用成功!'
        }
        const { code, data } = await this.$Api.dataMain.changeRowStatus({
          id,
          status
        })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: msg
          })
          this.queryApi() // 刷新下界面
        }
      } else {
        id = row.id
        rowStatus = row.status
        let status = ''
        let msg = ''
        if (rowStatus == '启用') {
          // 有效状态，需要停用
          status = 1
          msg = '停用成功!'
        } else {
          status = 0
          msg = '取消停用成功!'
        }
        const { code, data } = await this.$Api.dataMain.changeRowStatus({
          id,
          status
        })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: msg
          })
          this.queryApi() // 刷新下界面
        }
      }
    },
    // 只能解锁用户，不可锁定用户
    async lockUser(row, chooseMore) {
      let id = ''
      if (chooseMore == 1) {
        //多选
        id = this.selectIds
      } else {
        id = row.id
      }
      let msg = '解锁成功'
      const { code, data } = await this.$Api.dataMain.lockUser({
        id,
        failtures: 0 //只能解锁
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        this.queryApi() // 刷新下界面
      }
    },
    async addUser() {
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'add' }

      // await this.getscopeList()
      // const timestamp = Date.parse(new Date())
      // this.nowDate = stampToTime(timestamp)
      // this.drawer3 = true
      // this.addFlag = true
      // this.selectEditMsg = {
      //   appName: '',
      //   appCode: '',
      //   clientId: '',
      //   clientSecret: '',
      //   remoteUrl: '',
      //   scope: '',
      //   remark: '',
      //   status: 0 // 默认新增
      // } // 选中修改的一系列用户信息;
      // this.value = ''
    },

    async getScopeDataList() {
      const { code, data } = await this.$Api.dataMain.getOrgUnAssignList({
        id
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        console.log('909909', data)
      }
    },

    // 编辑信息
    editdRow(row) {
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'edit', singleId: row.id }
    },
    // 复制信息
    copy(row) {
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'copy', singleId: row.id }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.iconImg {
  width: 16px;
  height: 16px;
  &:last-child {
    margin-right: 0 !important;
  }
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.el-table {
  min-height: unset;
  max-height: unset !important;
}
.opeartionBox {
  // background: red;
  padding: 0px 0 16px 10px;
  box-sizing: border-box;
}
.buttonBigActive {
  margin-left: 16px;
  width: 96px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}

.el-table__body-wrapper,
.el-table__fixed-body-wrapper {
  min-height: 500px unset;
  max-height: unset;
}
::v-deep .el-dialog .el-dialog__body {
  padding: 16px 32px;
}
</style>
<style lang="scss">
.user-list-page {
  .el-button + .el-button {
    margin-left: 16px;
  }
  .el-table__header-wrapper .el-checkbox {
    display: none;
  }

  .buttonUnactive {
    width: 76px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ba9765;
    border-radius: 4px;
    color: #ba9765;
    font-size: 14px;
    line-height: 15px;
  }
  .buttonActive {
    width: 104px;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 15px;
  }
  .irisePocCardTable {
    //height: 100%;
    .el-card__header {
      height: 65px;
    }
  }
}
.minwidthBottom {
  .el-form-item {
    margin-bottom: 0 !important;
  }
}

</style>
